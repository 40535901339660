<template>
  <dash-page-new>
    <h1>Mobile apps businesses will be here</h1>
<code>businesses : {{businesses }}</code>
    <ws-data-table
        :items="businesses"
        :headers="headers"

    >
      <template #item.number="{index}">
        <h5>{{ index + 1 }}</h5>
      </template>
    </ws-data-table>
  </dash-page-new>
</template>


<script>
import {mapActions} from "vuex";

export default {
  name: "DashAdminMobile",
  data() {
    return {
      businesses : []
    }
  },
  computed : {
    headers() {
      return [
        {text: 'No', value: 'number' , width : 0},
        {text: this.$t('Name'), value: 'text'},
        {text: this.$t('Business'), value: 'value'}
      ]
    }
  },
  methods : {
    ...mapActions('mobileapps' , [
        'GET_BUSINESS_WITH_APPS'
    ]),

    async initPage() {
      this.businesses = await this.GET_BUSINESS_WITH_APPS()
      this.businesses = this.businesses.filter(el =>  !["fine" , "ios_first" , "academy"].includes(el.value) )
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>